export default {
  report: 'Raport',
  new: 'Nowy',
  sum: 'Zsumuj',
  time: 'Czas',
  quantity: 'Ilość',
  aggregated: 'Podsumowane',
  detailed: 'Szczegółowe',
  showNAlarms: 'Pokaż <b>{0}</b> najdłuższych alarmów',
  showNLongest: 'Pokaż <b>{0}</b> najdłuższych',
  showOthers: 'Wyświetl inne',
  hideOthers: 'Ukryj inne',
  sumOfSmaller: 'Suma innych',
  dropHereToCreateNew: 'Przeciągnij i upuść tutaj aby stworzyć nowy wykres',
  completedOrders: 'Ukończone zlecenia',
  history: 'Historia',
  usedEnergy: 'Zużyta energia',
  splitBy: 'Podziel na',
  addPersonal: 'Dashboard personalny',
  addFactory: 'Dashboard fabryki',
  unitConversionNotComplete: 'Niepełne przeliczenie jednostek',
  unitConversionComplete: 'Pełne przeliczenie jednostek',
  unableToFullyConvertUnitMessage: 'Produkcja nie może być całkowcie przekonwertowana do jednostki \'{unit}\', sprawdź definicję struktury pakowania SKU które były produkowane w tym okresie',
  work: 'Praca',
  plannedDowntime: 'Planowane',
  unplannedDowntime: 'Nieplanowane',
  expected: 'Oczekiwane',
  rootElement: 'Element źródłowy',
  performanceImpact: 'Straty wydajności',
  availabilityImpact: 'Straty dostępności',
  utilizationImpact: 'Straty wykorzystania',
  minorStops: 'Mikroprzestoje',
  viewMinorStops: 'Zobacz mikroprzestoje',
  minorStopsIncluded: 'Mikroprzestoje (Wliczone w całkowity czas trwania)',
  detailedEventsReport: 'Szczegółowy raport o wydarzeniach',
  siteOeeReport: 'Raport OEE zakładu',
  speedLossReport: 'Raport o utracie prędkości',
  labourVarianceReport: 'Raport o różnicach pracowniczych',
  siteWeeklyScorecard: 'Tygodniowa karta wyników',
  siteMonthlyScorecard: 'Miesięczna karta wyników',
  exportReport: 'Eksportuj raport',
  excludeUtilizationLoss: 'Wyklucz utratę wykorzystania',
  decrease: 'spadek',
  increase: 'wzrost',
  seeDowntimesReport: 'Pokaż w raporcie przestojów',
  downloadReport: 'Pobierz raport',
  numberOfItemsProduced: 'Ilość wyprodukowanych',
  compare: 'Porównaj',
  configureNewReportToCompare: 'Skonfiguruj nowy raport do porównania',
  oeeLoss: {
    oeeLoss: 'Strata OEE',
    timeLoss: 'Strata czasu',
    productionLoss: 'Strata produkcji',
    undefinedIssue: 'Typ problemu nieokreślony',
    utilizationLosses: 'Straty wykorzystania',
    availabilityLosses: 'Straty dostępności',
    performanceLosses: 'Straty wydajności',
    performanceStops: 'Przestoje strat wydajności',
    qualityLosses: 'Straty jakości',
    qualityStops: 'Przestoje strat jakości',
    speedLoss: 'Straty prędkości',
    qualityLoss: 'Straty jakości',
    speedIncrease: 'Zysk na prędkości',
    combined: 'Połączone',
    splitBy: 'Podzielone na',
    byMachine: 'Linie/maszyny',
    byProblem: 'Problemy',
    byState: 'Stany',
    byClassification: 'Klasyfikacje',
    base: 'Bazowy czas',
    classificationInfoTooltip: 'Aby zapoznać się z innym typem klasyfikacji, należy najpierw wybrać podział według „Klasyfikacji” w jednej z kategorii strat.',
    notInClassification: 'Nie w klasyfikacji',
    time: {
      totalTime: 'Całkowity czas',
      scheduledTime: 'Planowany czas',
      operatingTime: 'Czas działania',
      productionTime: 'Czas produkcji',
      effectiveTime: 'Czas efektywnej pracy',
    },
    calculatedBasedOnTotalAvailabilityLoss: 'Obliczone na podstawie całkowitej straty dostępności',
  },
  select: {
    allLines: 'Wszystkie linie',
    allMachines: 'Wszystkie maszyny',
    selectMachine: 'Wybierz maszynę',
    selectLine: 'Wybierz linię',
    clear: 'Wyczyść',
    selectLineAreaOrPlant: 'Wybierz fabrykę, linię lub obszar',
  },
  alarms: {
    description: 'Opis',
    numberOfOccurances: 'Wystąpienia',
    duration: 'Czas trwania',
    sumOfSmallerAlarms: 'Suma mniejszych alarmów',
    noDataInThisPeriod: 'Brak danych w tym okresie czasu',
  },
  selectors: {
    amount: 'Ilość',
    period: 'Okres',
    moment: 'Moment',
    dateTo: 'Data do',
    dateFrom: 'Data od',
    shift: 'Zmiana',
    day: 'Dzień',
    week: 'Tydzień',
    month: 'Miesiąc',
    year: 'Rok',
    today: 'Dzisiaj',
    yesterday: 'Wczoraj',
    thisWeek: 'Ten tydzień',
    previousWeek: 'Poprzedni tydzień',
    last7Days: 'Ostatnie 7 dni',
    thisMonth: 'Ten miesiąc',
    previousMonth: 'Poprzedni miesiąc',
    selectLineAndMachine: 'Wybierz linię i maszynę',
    selectLineOrMachine: 'Wybierz linię lub maszynę',
    selectLine: 'Wybierz linię',
    timeFilter: 'Filtr czasu',
    lastThreeMonths: 'Ostatnie 3 miesiące',
    selectExactDates: 'Wybierz dokładne daty',
    selectDatesFromList: 'Wybierz daty z listy',
    addElement: 'Dodaj element fabryki do raportu',
    thisYear: 'Ten rok',
    previousYear: 'Poprzedni rok',
  },
  types: {
    custom: 'Własne',
    oeeDetails: 'Szczegóły OEE',
    availability: 'Dostępność',
    efficiency: 'Wydajność',
    oee: 'OEE',
    oeeLosses: 'Straty OEE',
    production: 'Produkcja',
    productionMap: 'Mapa produkcji',
    downtimes: 'Przestoje',
    rootcauses: 'Źródła Przestojów',
    comments: 'Komentarze z przestojów',
    alarms: 'Alarmy',
    column: 'Kolumnowy',
    pie: 'Kołowy',
    operatorStops: 'Zatrzymania operatorów',
    downtimeDuration: 'Czas trwania przestoju',
    downtimeDurations: 'Czasy trwania przestojów',
    reasons: 'Przyczyny',
    energy: 'Energia',
    energySankey: 'Sankey',
    energyHistory: 'Historia Zużycia',
    reportsDownload: 'Pobierz raporty',
    heatmap: 'Heatmap',
    commentsFromLosses: 'Komentarze strat',
    typeOfLosses: 'Typ Strat',
    downtimeTracking: 'Śledzenie przestojów',
    energyTrends: 'Trendy',
  },
  operatorStop: {
    waiting: 'Średni czas oczekiwania na akcję',
    fixing: 'Średni czas rozwiązywania problemu',
    stopsBecauseOf: 'Zatrzymań przez',
    timeSum: 'Sumaryczny czas',
  },
  rootcause: {
    impact: {
      title: 'Wpływ',
      desc: '(Czas efektu przestoju / Czas trwania przestoju)',
    },
    effectDuration: 'Czas efektu przestoju',
    rootcauseDuration: 'Czas trwania przestoju',
  },
  chart: {
    toggleFullView: 'Przełącz pełny widok',
  },
  chooseEvent: 'Wybierz zdarzenie',
  needToSelectEvent: 'Musisz wybrać zdarzenie',
  downtimesPerProducedUnit: 'Przestoje na wyprodukowaną jednostkę',
  trackerOptions: 'Ustawienia śledzenia przestojów',
  expandCollapseAll: 'Rozwiń/Zwiń wszystko',
  downtimeComments: {
    comment: 'Komentarz',
    downtimeElement: 'Linia/Maszyna',
    downtime: 'Przestój',
    time: 'Czas',
    duration: 'Czas trwania',
    commentAuthor: 'Autor komentarza',
    showDowntime: 'Pokaż Przestój',
    details: 'Detale',
  },
  downtimeTracker: {
    track: 'Śledź',
    resolutionForThisSelection: 'Zawęź rozdzielczość dla tego wyboru',
    workspectrumForThisSelection: 'Pokaż widmo pracy dla tego wyboru',
    view: 'Widok',
    searchAProblem: 'Wyszukaj problem...'
  },
  reportResolutionIsLongerThanReportPeriodInfo: 'Precyzja raportu jest ustawiona na dłuższy zakres niż zakres dat raportu!'

};
