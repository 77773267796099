<template>
  <div v-if="element.type === elementType.line">
    <PlantLine
      v-if="!isMobile"
      :is-header="isHeader"
      :editing-downtime="editingDowntime"
      v-bind="element"
      @openProblems="$emit('openProblems')"
      @currentStateEdit="$emit('currentStateEdit', $event)"
      @currentStateEditRefresh="$emit('currentStateEditRefresh', $event)"
    />
    <MobileLine
      v-else
      :is-header="isHeader"
      v-bind="element"
    />
  </div>
  <div v-else-if="element.kind === 'monitor'">
    <Monitor
      v-bind="element"
    />
  </div>
  <div v-else>
    <div class="row justify-content-center header-row">
      <h4 class="text-fancy">
        {{ element.name }}
      </h4>
    </div>
    <hr
      class="mt-3 mb-3"
      style="width: 80%"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlantLine from '@/components/line/PlantLine';
import MobileLine from '@/components/line/MobileLine';
import Monitor from '@/components/element/aggregates/Monitor';
import { elementType } from '@/utils/dictionary';

export default {
  props: {
    element: {
      type: Object,
      required: true,
    },
    isHeader: Boolean,
    editingDowntime: Boolean,
  },
  data: () => ({
    elementType,
  }),
  components: {
    MobileLine,
    PlantLine,
    Monitor,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
  @media (min-width: 1000px) {
    .header-row {
      height: 180px;
    }
  }
</style>
