import moment from 'moment';
import { plantProperty } from '@/utils/dictionary';
import lang from '@/lang';

const format = d => moment(d).format('HH:mm');

const shifts = (getters, dayStart, futureShifts = false) => {
  const { aggregatedDayShifts } = getters;
  const dateFrom = moment(dayStart).subtract(1, 'days').unix();
  const dateTo = moment(dayStart).endOf('day').unix();

  if (!aggregatedDayShifts) return [];

  return aggregatedDayShifts(dateFrom, dateTo).map(s => {
    const isYesterday = s.startDate < getters.userDayStart;
    return {
      key: `shift${isYesterday ? 'y' : ''}${s.shiftId}`,
      name: `${s.name} (${isYesterday
        ? lang.t('reports.selectors.yesterday')
        : lang.t('reports.selectors.today')}
        ${format(s.startDate * 1000)} - ${format(s.endDate * 1000)})`,
      startDate: s.startDate,
      endDate: s.endDate,
    };
  }).filter(a => a.startDate < moment().unix() || futureShifts)
    .sort((a, b) => b.startDate - a.startDate || a.name.localeCompare(b.name));
};

const days = (getters, dayStart) => {
  const unix = dayStart.valueOf();
  const result = [
    {
      key: 'today',
      name: `${lang.t('reports.selectors.today')} (${lang.t('general.from')} ${format(unix)})`,
      startDate: dayStart.unix(),
      endDate: moment(dayStart).add(1, 'd').unix(),
    },
    {
      key: 'yesterday',
      name: `${lang.t('reports.selectors.yesterday')} (${lang.t('general.from')} ${format(unix)})`,
      startDate: moment(dayStart).subtract(1, 'd').unix(),
      endDate: dayStart.unix(),
    },
  ];

  for (let i = 2; i < 7; i += 1) {
    result.push({
      key: `day${i}`,
      name: `${moment(dayStart).subtract(i, 'd').format('ddd Do')} (${lang.t('general.from')} ${format(unix)})`,
      startDate: moment(dayStart).subtract(i, 'd').unix(),
      endDate: moment(dayStart).subtract(i - 1, 'd').unix(),
    });
  }
  return result;
};

const weeks = (getters, dayStart) => [
  {
    key: 'thisWeek',
    name: lang.t('reports.selectors.thisWeek'),
    startDate: moment(dayStart).startOf('week')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).endOf('week')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
  {
    key: 'previousWeek',
    name: lang.t('reports.selectors.previousWeek'),
    startDate: moment(dayStart).subtract(1, 'week').startOf('week')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(1, 'week').endOf('week')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
  {
    key: 'last7Days',
    name: lang.t('reports.selectors.last7Days'),
    startDate: moment(dayStart)
      .subtract(1, 'week')
      .startOf('day')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart)
      .startOf('day')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
];

const months = (getters, dayStart) => [
  {
    key: 'thisMonth',
    name: lang.t('reports.selectors.thisMonth'),
    startDate: moment(dayStart).startOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).endOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
  {
    key: 'previousMonth',
    name: lang.t('reports.selectors.previousMonth'),
    startDate: moment(dayStart).subtract(1, 'month').startOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(1, 'month').endOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
  {
    key: 'lastThreeMonths',
    name: lang.t('reports.selectors.lastThreeMonths'),
    startDate: moment(dayStart).subtract(2, 'month').startOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).endOf('month')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
];

const years = (getters, dayStart) => [
  {
    key: 'thisYear',
    name: lang.t('reports.selectors.thisYear'),
    startDate: moment(dayStart).startOf('year')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).endOf('year')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
  {
    key: 'previousYear',
    name: lang.t('reports.selectors.previousYear'),
    startDate: moment(dayStart).subtract(1, 'year').startOf('year')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(1, 'year').endOf('year')
      .add(getters.dayStartOffsetInUserTimezone, 'seconds')
      .unix(),
  },
];

const displayOffSet = getters => {
  const userTime = moment.tz(getters.timezone).utcOffset() * 60;
  const plantTime = moment.tz(getters.plantTimezone).utcOffset() * 60;
  return userTime - plantTime;
};

const getTodayText = (startDate, getters) => {
  // Display precise day start only if in a different timezone
  // Or day start is different then 00:00
  if (getters.dayStart || getters.inDifferentTimezone) {
    const tzDayStart = moment(startDate * 1000).tz(getters.timezone);
    return `${lang.t('today')} (${lang.t('general.from')} ${tzDayStart.format('H:mm')})`;
  }
  return lang.t('today');
};

const getThisWeekText = startDate =>
  // return lang.t('today');
  `${lang.t('currentWeek')} (${lang.t('general.from')} ${startDate.format('DD MMM')})`;

export default {
  state: {
    now: moment().unix(),
    hourNow: moment().unix(),
    selectedDate: localStorage.getItem('monitoring:selectedDate') || 'today',
  },

  mutations: {
    setDateScope(s, name) {
      s.selectedDate = name;
      localStorage.setItem('monitoring:selectedDate', name);
    },
    updateHourNow(s) {
      s.hourNow = moment().unix();
    },
  },

  getters: {
    hourNow: s => s.hourNow,
    plantNow: (s, g) => now => moment(now * 1000).tz(g.plantTimezone),
    dayStart: (_, g) => moment().startOf('day').add(g.dayStartOffsetInUserTimezone, 'seconds').unix(),
    dayEnd: (_, g) => moment().endOf('day').add(g.dayStartOffsetInUserTimezone, 'seconds').unix(),
    propertyDayStart: (s, g, rs, rg) => Number(rg['plant/plantProperty'](plantProperty.dayStart) || '0'),
    dayStartOffsetInUserTimezone: (_, g, rs, rg) => rg.propertyDayStart + displayOffSet(g),
    plantTimezone: (s, g, rs, rg) =>
      (rg['plant/plant'](rg.plantId) ? rg['plant/plant'](rg.plantId).timeZone : null),
    inDifferentTimezone: (s, g) =>
      g.plantTimezone && moment().tz(g.plantTimezone).utcOffset() !== moment().utcOffset(),
    userDayStart: (s, g) => {
      if (!g.plantTimezone) {
        return moment(g.dayStart * 1000).unix();
      }

      const start = moment(s.hourNow * 1000)
        .startOf('day').add(g.dayStartOffsetInUserTimezone, 'seconds');

      if (start.isAfter(moment())) {
        return start.subtract(1, 'day').unix();
      }

      return start.unix();
    },
    startDate: (s, g) => {
      const ds = g.selectedDateScope;
      return ds && ds.startDate ? ds.startDate : g.dayStart;
    },
    endDate: (s, g) => {
      const ds = g.selectedDateScope;
      return ds && ds.endDate ? ds.endDate : g.dayEnd;
    },
    selectedDateScope: (s, g) => {
      const ds = g.dateScopes.find(el => el.name === s.selectedDate);
      if (!ds) {
        return g.dateScopes.find(x => x.name === 'today');
      }
      return ds;
    },
    dateScopes: (s, g) => {
      const dayStartDate = g.userDayStart;

      const scopes = [
        {
          name: 'today',
          startDate: dayStartDate,
          text: getTodayText(dayStartDate, g),
          endDate: moment.unix(dayStartDate).add(24, 'hours').unix(),
        },
        {
          name: 'week',
          startDate: moment.unix(dayStartDate)
            .startOf('week')
            .add(g.dayStartOffsetInUserTimezone, 'seconds')
            .unix(),
          text: getThisWeekText(moment.unix(dayStartDate)
            .startOf('week')
            .add(g.dayStartOffsetInUserTimezone, 'seconds')),
          endDate: moment.unix(dayStartDate)
            .startOf('week')
            .add(7, 'd')
            .add(g.dayStartOffsetInUserTimezone, 'seconds')
            .unix(),
        },
        {
          name: 'shift',
          text: lang.t('currentShift'),
        },
        {
          name: 'order',
          text: lang.t('currentOrder'),
        },
      ];

      return scopes;
    },
    splitByShift: (s, g) => g.selectedDateScope.name === 'shift',
    splitByOrder: (s, g) => g.selectedDateScope.name === 'order',
    allDateScopes: (s, g) => {
      const ds = g.userDayStart;

      return [
        {
          key: 'shift',
          name: lang.t('reports.selectors.shift'),
          moments: shifts(g, moment.unix(ds)),
        },
        {
          key: 'day',
          name: lang.t('reports.selectors.day'),
          moments: days(g, moment.unix(ds)),
        },
        {
          key: 'week',
          name: lang.t('reports.selectors.week'),
          moments: weeks(g, moment.unix(ds)),
        },
        {
          key: 'month',
          name: lang.t('reports.selectors.month'),
          moments: months(g, moment.unix(ds)),
        },
        {
          key: 'year',
          name: lang.t('reports.selectors.year'),
          moments: years(g, moment.unix(ds)),
        },
      ];
    },
    // similar to allDateScopes but returns future shifts
    fullDateScopes: (s, g) => {
      const ds = g.userDayStart;

      return [
        {
          name: lang.t('reports.selectors.shift'),
          moments: shifts(g, moment.unix(ds), true),
        },
        {
          name: lang.t('reports.selectors.day'),
          moments: days(g, moment.unix(ds)),
        },
        {
          name: lang.t('reports.selectors.week'),
          moments: weeks(g, moment.unix(ds)),
        },
        {
          name: lang.t('reports.selectors.month'),
          moments: months(g, moment.unix(ds)),
        },
      ];
    },
    getScopeText: (s, g) => scope => {
      if (!scope) return '';
      const { startDate, name, text } = scope;
      if (name === 'today') {
        return getTodayText(startDate, g);
      }
      return text;
    },
    currentScopeText: (s, g) => g.getScopeText(g.selectedDateScope),
    simpleCurrentScopeText: (s, g) => {
      const { name, text } = g.selectedDateScope;
      if (name === 'today') {
        return lang.t('today');
      }
      return text;
    },
  },
};
