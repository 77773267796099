<template>
  <div class="d-flex flex-column justify-content-around">
    <div class="d-flex flex-column justify-content-center state-container">
      <CurrentState
        :current-state="currentState"
        :element-id="lineId"
      />
    </div>

    <div class="d-flex justify-content-around line-buttons mt-1">
      <BOverlay
        :show="loadingSelectedDowntime"
        spinner-small
        spinner-variant="primary"
      >
        <div
          v-if="isDowntime && !orderActivated && canEditWorklogs"
          v-tippy="{ arrow: true }"
          :content="$t('monitoring.changeCurrentDowntime')"
          class="state-icon downtime-change"
          @click.stop="changeCurrentDowntime"
        >
          <i class="fas fa-exchange-alt" />
        </div>
      </BOverlay>

      <LinePauseButton
        v-if="currentState && !orderActivated && canEditWorklogs"
        :is-forced="currentState ? currentState.isForced : false"
        :line-id="lineId"
      />

      <div
        v-if="currentState && currentState.correctiveActions && currentState.correctiveActions.length > 0"
        class="state-icon-container"
      >
        <CorrectiveActionPopover
          :corrective-actions="currentState.correctiveActions"
          @modal="caModal = $event"
        >
          <div class="state-icon wand">
            <i class="icon-corrective-action" />
          </div>
        </CorrectiveActionPopover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PACKOS_DOWNTIME_MANAGEMENT, PACKOS_MANUAL_DOWNTIME } from '@core/dict/permissions';
import colors from '@/utils/colors';
import CalcDuration from '@/utils/calcDuration';
import CorrectiveActionPopover from '@/components/popovers/CorrectiveActions';
import CurrentState from '@/components/element/CurrentState';
import worklogParser from '@/components/downtimes/worklogParser';
import offcanvasDowntimeEditMixin from '@/mixins/offcanvasDowntimeEditMixin';
import LinePauseButton from './LinePauseButton';

export default {
  props: {
    plantId: String,
    currentState: Object,
    lineId: String,
    activeOrders: Array,
    editingDowntime: [Object, Boolean],
  },
  data: () => ({
    popover: false,
    editingWorkTypes: false,
    reasonId: null,
    saving: false,
    interval: null,
    caModal: false,
    currentStateEdit: null,
    loadingSelectedDowntime: false,
    showOffcanvas: false,
  }),
  components: {
    CorrectiveActionPopover,
    LinePauseButton,
    CurrentState,
  },
  mixins: [
    worklogParser,
    offcanvasDowntimeEditMixin,
  ],
  computed: {
    ...mapGetters('core', ['canOnElement']),
    ...mapGetters('work', ['workStateOfEvent']),
    ...mapGetters('work', {
      workEventGetter: 'workEvent',
      workStateGetter: 'workState',
      bufferedWorkLog: 'bufferedWorkLog',
      parseWorklog: 'parseWorklog',
    }),
    ...mapGetters('element', [
      'elementName',
      'elementType',
    ]),
    canEditWorklogs() {
      return this.canOnElement(PACKOS_DOWNTIME_MANAGEMENT, this.lineId)
        || this.canOnElement(PACKOS_MANUAL_DOWNTIME, this.lineId);
    },
    orderActivated() {
      if (!this.activeOrders || this.activeOrders.length === 0) return false;
      return this.activeOrders[0].status?.toLowerCase() === 'activated';
    },
    workState() {
      const { eventId } = this.currentState;
      return this.workStateOfEvent(eventId);
    },
    workEvent() {
      if (!this.currentState) return null;
      const { eventId } = this.currentState;
      return this.workEventGetter(eventId);
    },
    lastMessage() {
      return this.healthcheck.lastMessage ? CalcDuration(this.healthcheck.lastMessage) : null;
    },
    haveCorrectiveActions() {
      return this.currentState
        && this.currentState.correctiveActions
        && this.currentState.correctiveActions.length > 0;
    },
    isDowntime() {
      if (!this.currentState) return false;
      const state = this.workStateOfEvent(this.currentState.eventId);
      return state && state.name.toLowerCase() !== 'work' && state.name.toLowerCase() !== 'nolivedata';
    },
    getWorkEventMachine() {
      const { currentState } = this;
      if (!currentState || currentState.isForced || !currentState.rootWorkLog) return '';
      const { elementId } = currentState.rootWorkLog;
      const machine = this.$store.getters['element/element'](elementId);
      return machine ? `${machine.name}` : '';
    },
    getWorkEvent() {
      const { currentState } = this;
      if (!currentState) {
        return this.$t('general.reasonUnknown');
      }
      const { workEvent } = this;
      if (!workEvent) return '';
      if (!currentState.rootWorkLog) {
        return workEvent.isDefault ? '' : workEvent.name;
      }

      return workEvent.isDefault ? '' : workEvent.name;
    },
  },
  watch: {
    async currentState(v) {
      if (!v || !this.currentStateEdit || !this.editing) return;
      if (
        v.eventId !== this.currentStateEdit.eventId
        || v?.rootWorkLog?.eventId !== this.currentStateEdit?.rootWorkLog?.eventId
        || v.endDate !== this.currentStateEdit.endDate
        || v.startDate !== this.currentStateEdit.startDate
      ) {
        const parsedWorklog = this.parseWorklog(this.currentState);
        const worklog = this.worklogParser(parsedWorklog);
        this.currentStateEdit = await this.getReasonsAndCorrectiveActionsForDowntime(worklog);

        this.$emit('currentStateEditRefresh', this.currentStateEdit);
      }
    },
  },
  methods: {
    ...mapActions('work', [
      'getWorkEventDetails',
    ]),
    colors,
    CalcDuration,
    async getReasonsAndCorrectiveActionsForDowntime(d) {
      const downtime = { ...d };
      const { data } = await this.getWorkEventDetails({
        params: {
          plantId: this.plantId,
          query: {
            id: downtime.workEvent.id,
          },
        },
      });
      downtime.workEvent = data[0];

      if (downtime.rootWorkLog) {
        const { data: rootData } = await this.getWorkEventDetails({
          params: {
            plantId: this.plantId,
            query: {
              id: downtime.rootWorkLog.workEvent.id,
            },
          },
        });
        downtime.rootWorkLog.workEvent = rootData[0];
      }
      return downtime;
    },
    async changeCurrentDowntime() {
      this.loadingSelectedDowntime = true;

      const parsedWorklog = this.parseWorklog(this.currentState);
      const worklog = this.worklogParser(parsedWorklog);
      this.currentStateEdit = await this.getReasonsAndCorrectiveActionsForDowntime(worklog);

      this.loadingSelectedDowntime = false;

      this.$emit('currentStateEdit', this.currentStateEdit);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .line-buttons {
    max-width: 170px;
    margin: 0 auto;
  }

  .state-container {
    max-height: 100px;
    padding-bottom: 5px;
  }

  .line-buttons :deep(.state-icon) {
    transition: border-color 500ms, background-color 500ms, transform 300ms;
    text-align: center;
    font-size: 13px;
    color: #585858;
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    margin: 0 10px;

    &.downtime-change:hover {
      border-color: $red;
      transform: scale(1.2);
      background-color: rgba($red, 0.1);
    }

    &.wand:hover {
      transform: scale(1.2);
      border-color: #333;
      background-color: rgba(#333, 0.1);
    }

    &.pause:hover {
      transform: scale(1.2);
      border-color: #333;
      background-color: rgba(#333, 0.1);
    }
  }

  .state-info {
    line-height: 1;
  }

  .state-icon-container {
    width: 45px;
    height: 25px;
  }

  .state-duration {
    font-weight: bold;
    font-size: 10px;
  }

  .work-event {
    font-size: 14px;
    font-weight: 400;
  }

  .reason-comment {
    font-size: 11px;
    line-height: 1.1;
  }

  .action {
    font-size: 13px;
    font-weight: 300;
  }

  .floating-save {
    position: fixed;
    z-index: 1051;
    bottom: 20px;
    left: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .line-buttons :deep(.state-icon), .state-icon-container {
      height: 45px;
      width: 45px;
      line-height: 45px;
    }
  }
</style>
